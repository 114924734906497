// fxLayout="row wrap", fxLayout="column wrap"
.flex-row { display: flex !important; flex-direction: row; }
.flex-col { display: flex !important; flex-direction: column; }
.flex-wrap { flex-wrap: wrap !important; }

// fxFlex percentage
// fxFlex="100" => class="flex-100"
$flexWidths: 0, 1, 2, 3, 5, 8, 10, 12, 15, 17, 20, 30, 34, 35, 40, 45, 50, 60, 69, 70, 75, 80, 83, 85, 88, 90, 100;
@each $el in $flexWidths {
    .flex-#{$el} {
        display: flex !important;
        flex: 1 1 ($el * 1%);
        box-sizing: border-box;
        @if $el > 0 {
            max-width: $el * 1%;
        }
    }
}

// fxFlex fixed in 'px'
// fxFlex="50px" => class="flex-fixed-50"
$flexFixedWidths: 24, 25, 30, 40, 50, 80, 118, 140, 200, 210, 250, 300, 315, 380, 384, 408, 500;
@each $el in $flexFixedWidths {
    .flex-fixed-#{$el} {
        display: flex !important;
        max-width: $el * 1px;
        flex: 1 1 ($el * 1%);
        box-sizing: border-box;
    }
}

// fxLayoutGap
// fxLayoutGap="15px" => class="flex-gap-15"
$gaps: 3, 4, 5, 8, 10, 12, 14, 15, 16, 18, 20, 24, 25, 26, 30, 32, 35;
@each $gap in $gaps {
    .flex-gap-#{$gap} {
        display: flex !important;
        gap: $gap * 1px;
    }
}

// fxLayoutAlign
// fxLayoutAlign="space-between center" => class="justify-between items-center"
// fxLayoutAlign="center" => class="justify-center"
.justify-between { justify-content: space-between !important; }
.justify-around { justify-content: space-around !important; }
.justify-center { justify-content: center !important; }
.justify-start { justify-content: flex-start !important; }
.justify-end { justify-content: flex-end !important; }
.items-center { align-items: center !important; }
.items-start { align-items: start !important; }
.items-end { align-items: end !important; }
.items-stretch { align-items: stretch !important; }


// fxFlex="calc(98% - 60px)" => {
//   display: flex;
//   max-width: calc(98% - 60px);
//   flex: 1 1 calc(98% - 60px);
// }

// Hide & show for different breakpoints. (.hide-xs, .hide-gt-xs, .show-lg, .show-gt-lg)
$breakpoints: ('xs': 599, 'sm': 959, 'md': 1279, 'lg': 1919, 'xl': 5000);
@each $bp, $value in $breakpoints {
    .hide-#{$bp} {
        @media screen and (max-width: ($value * 1px)) { display: none; }
    }
    .hide-gt-#{$bp} {
        @media screen and (min-width: (($value + 1) * 1px)) { display: none; }
    }
    .show-#{$bp} {
        @media screen and (max-width: ($value * 1px)) { display: block; }
    }
    .show-gt-#{$bp} {
        @media screen and (min-width: (($value + 1) * 1px)) { display: block; }
    }
}