/* Spaces */

.margin-0 {
  margin: 0 !important;
}

.margin-right-50 {
  margin-right: 50px;
}

.margin-right-25 {
  margin-right: 25px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-right-15 {
  margin-right: 15px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-left-50 {
  margin-left: 50px;
}

.margin-left-35 {
  margin-left: 35px;
}

.margin-left-30 {
  margin-left: 30px;
}

.margin-left-25 {
  margin-left: 25px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-left-16 {
  margin-left: 16px;
}

.margin-left-15 {
  margin-left: 15px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-25 {
  margin-top: 25px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-4 {
  margin-top: 4px;
}

.margin-top-0 {
  margin-top: 0;
}

.mt-24 {
  margin-top: 24px;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-bottom-12 {
  margin-bottom: 10px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.margin-bottom-25 {
  margin-bottom: 25px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-24 {
  margin-bottom: 24px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-x-auto {
  margin-left: auto;
  margin-right: auto;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-top-15 {
  padding-top: 15px;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-top-5 {
  padding-top: 5px;
}

.padding-15 {
  padding: 15px;
}

.padding-10 {
  padding: 10px;
}

.padding-5 {
  padding: 5px;
}

.padding-left-20 {
  padding-left: 20px;
}

.padding-left-15 {
  padding-left: 15px;
}

.padding-left-10 {
  padding-left: 10px;
}

.padding-left-0 {
  padding-left: 0px;
}

.padding-left-5 {
  padding-left: 5px;
}

.padding-left-12 {
  padding-left: 12px;
}

.padding-right-20 {
  padding-right: 20px;
}

.padding-right-15 {
  padding-right: 15px;
}

.padding-right-10 {
  padding-right: 10px;
}

.padding-right-12 {
  padding-right: 12px;
}

.padding-right-5 {
  padding-right: 5px;
}

.padding-right-0 {
  padding-right: 0px;
}

.padding-bottom-15 {
  padding-bottom: 15px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.padding-bottom-5 {
  padding-bottom: 5px;
}

.padding-0 {
  padding: 0;
}

.padding-24 {
  padding: 24px;
}

.padding-16 {
  padding: 16px;
}

/* Backgrounds */

.background-color-primary-900 {
  background-color: $color-primary-900;
}

.background-color-primary-800 {
  background-color: $color-primary-800;
}

.background-color-primary-700 {
  background-color: $color-primary-700;
}

.background-color-primary-600 {
  background-color: $color-primary-600;
}

.background-color-primary-500 {
  background-color: $color-primary-500;
}

.background-color-primary-400 {
  background-color: $color-primary-400;
}

.background-color-primary-300 {
  background-color: $color-primary-300;
}

.background-color-primary-200 {
  background-color: $color-primary-200;
}

.background-color-primary-100 {
  background-color: $color-primary-100;
}

.background-color-primary-50 {
  background-color: $color-primary-50;
}

.mat-green {
  color: $white;
  background-color: $light-green;
}

.mat-orange {
  color: $white;
  background-color: $orange;
}

.white-background {
  background-color: $white;
}

/* Alignment */

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.float-right {
  float: right;
}

.align-center {
  display: flex;
  align-items: center;
  text-align: center;
}

/* Other */

.no-select {
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.hidden {
  display: none;
}

.hide-scroll {
  overflow-y: hidden;
}

.overlay {
  pointer-events: none;
  opacity: 0.5;
}

.table-loading {
  pointer-events: none;
  tbody {
    // To contain loading overlay:
    position: relative;

    // app-table-loading-overlay has heigh:50px as default, to make sure it is visible when table is empty
    // Remove this height when mat-row.cdk-row exist (table has content):
    tr.mat-mdc-row.cdk-row + app-table-loading-overlay {
      min-height: auto;
      height: auto;
    }
  }

  // Disable pagination:
  & ~ div {
    & > app-page-selector {
      pointer-events: none;
    }
  }
  & ~ app-page-selector {
    pointer-events: none;
  }
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.float-left {
  float: left;
}

.break-word {
  word-break: break-word;
}

.break-all {
  word-break: break-all;
}

.green-underline {
  color: $color-primary-500;
  text-decoration: underline;
}

.red-underline {
  color: $error;
  text-decoration: underline;
}

.color-green {
  color: $green;
}

.cursor-pointer {
  cursor: pointer;
}

.d-none {
  display: none;
}

.text-color-primary {
  color: $green;
}
.text-color-secondary {
  color: inherit;
}
.text-color-warn {
  color: $orange;
}
.text-color-danger {
  color: $error;
}
.text-color-cancel {
  color: $cancel-gray;
}

.w-100 {
  width: 100%;
}

.height-0 {
  height: 0;
}

.h-100 {
  height: 100%;
}

.multiline {
  white-space: pre-line;
}

.pre-line-clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.block {
  display: block !important;
}

.white-space-nowrap {
  white-space: nowrap
}
.position-relative {
  position: relative;
}

.width-100 {
  width: 100%;
}

.width-50 {
  width: 50%;
}

.height-100 {
  height: 100%;
}
