.mat-mdc-form-field {
  &.mat-form-field-appearance-outline {
    .mat-mdc-form-field-infix {
      padding: 0.75rem 0 0.75rem;
      z-index: 2;
      min-height: 0px;

      input {
        font-weight: 600;
        font-size: 1rem;
        box-sizing: content-box;
        height: 18px;
        line-height: 18px;
        letter-spacing: normal;
      }
      .mat-mdc-form-field-label-wrapper {
        top: -1.6rem;
      }
    }
    .mat-mdc-form-field-label {
      margin-top: 0rem;
      top: 2.1rem;
    }
  }
  &.no-x-padding {
    .mat-mdc-text-field-wrapper {
      padding-left: 0;
      padding-right: 0;
    }
  }
  &.form-field-white-background {
    .mat-mdc-text-field-wrapper {
      background-color: $white !important;
    }
  }
}
.mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
  font-weight: 600;
}
.mat-mdc-form-field.no-bottom-padding {
  .mat-mdc-text-field-wrapper {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
}

.mat-mdc-form-field.mat-form-field-appearance-outline {
  &.btr-form-field {
    &.mat-mdc-form-field-should-float {
      .mat-mdc-form-field-infix .mat-mdc-form-field-label-wrapper {
        top: -1.7rem;
        height: 2.5rem;
      }
    }
    .mat-mdc-form-field-infix .mat-mdc-form-field-label-wrapper {
      top: -1.95rem;
      height: 2.5rem;
    }
    .mat-mdc-form-field-infix {
      padding: 0rem 0 0.5em;
      border-top: 10px solid transparent;
    }

    &.white-background {
      .mat-mdc-form-field-outline-end,
      .mat-mdc-form-field-outline-start {
        background-color: $white;
      }
    }

    &.grey-background {
      .mat-mdc-form-field-outline {
        color: $complex-dropdown-grey;
        border: 1px solid $gainsboro-gray;
      }
      .mat-mdc-form-field-outline-gap {
        background-color: $complex-dropdown-grey;
      }
      .mat-mdc-select-value {
        color: #525a66;
        @include app-text-style(0.875rem, 1.43, -0.4px, 600);
      }
      .mat-mdc-select-arrow {
        color: #525a66;
      }
      .mat-mdc-select-min-line {
        margin-left: 87px;
      }

      .initial {
        .mat-mdc-select-min-line {
          margin-left: 0;
          color: #6f7681;
        }
      }
      .mat-mdc-form-field-infix {
        padding: 0.1rem 0 0.6em;

        .image-container {
          position: absolute;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          width: 66px;
          height: 22px;
          margin-right: 16px;
          overflow: hidden;
          border-radius: 2px;
          background-color: $white;
          top: 2px;

          img {
            max-width: 100%;
            height: auto;
          }
        }
      }
      .mat-mdc-form-field-outline-end,
      .mat-mdc-form-field-outline-start {
        background-color: #f8f8f8;
      }
    }
  }
  .mat-mdc-select-value-text {
    font-weight: $semi-bold-font;
  }
  .mat-mdc-select-arrow-wrapper {
    transform: translateY(0%);
  }
}
.mat-mdc-form-field.mat-mdc-warn .mat-mdc-input-element,
.mat-mdc-form-field-invalid .mat-mdc-input-element {
  caret-color: $error;
}

.mat-mdc-form-field-error {
  font-size: 12px;
}

mat-hint {
  &.mat-mdc-form-field-hint {
    @include app-text-style(0.75rem, 1.67, -0.2px);
    color: $dark-petrol-grey !important;
  }

  &.outer-hint {
    display: contents;
  }
}

.mat-mdc-form-field.mat-form-field-appearance-outline.mat-form-field-disabled .mdc-notched-outline {
  background-color: $gray-3;
}

.mat-mdc-form-field.mat-form-field-appearance-outline .mdc-notched-outline {
  background-color: white;
  border-radius: 5px;

  .mdc-floating-label:not(.mdc-floating-label--float-above) {
    top: 20px;
  }
}

.mat-mdc-select-panel.complex-select {
  .mat-mdc-option {
    background-color: $complex-dropdown-grey;
    border-bottom: 1px solid rgba(33, 33, 33, 0.08);
    max-width: 438px;

    &:hover {
      background-color: $gray-3;
    }

    &.mat-mdc-selected {
      background-color: $green;

      &:hover {
        background-color: $color-primary-700;
      }

      .wrapper .name,
      .wrapper .icon {
        color: $white;
      }
    }
  }
}

.mat-mdc-select-panel.pass-level-select {
  max-height: 486px;
  margin-left: 4px;
  min-width: calc(100% + 25px) !important;

  .mat-mdc-option {
    line-height: inherit;
    height: auto;
    padding: 14px 16px;
    border-bottom: 1px solid $gray-3;

    &.mat-mdc-selected,
    &.mat-mdc-active {
      .wrapper {
        .name {
          color: $white;
        }

        .list {
          color: $color-primary-100;
        }
      }
    }

    &.mat-mdc-selected:not(.mat-mdc-option-multiple) {
      background-color: $green;
    }
  }
}

.mat-mdc-form-field-has-label.mat-form-field-appearance-outline.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float
  .mat-mdc-form-field-outline-gap {
  padding-right: 5px;
  box-sizing: content-box;
}

.mat-mdc-form-field.mat-mdc-form-field-appearance-legacy.mat-mdc-form-field-disabled .mat-mdc-form-field-underline {
  background-image: none !important;
}

.mat-mdc-form-field {
  .mat-mdc-form-field-icon-prefix > .mat-icon,
  .mat-mdc-form-field-icon-suffix > .mat-icon {
    padding: 0;
    cursor: pointer;
  }
}

// Change borders for form fields that are not in "search menu":
div:not(.mat-mdc-menu-content) { 
  >.mat-mdc-form-field:not(.mat-form-field-disabled):not(.mat-form-field-invalid):not(.no-hover) {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mdc-notched-outline {
          .mdc-notched-outline__leading,
          .mdc-notched-outline__trailing {
            border-top: $light-gray-rgba;
            border-bottom: $light-gray-rgba;
          }
          .mdc-notched-outline__leading {
            border-left: $light-gray-rgba;
          }
          .mdc-notched-outline__trailing {
            border-right: $light-gray-rgba;
          }
          .mdc-notched-outline__notch {
            border-top: $light-gray-rgba;
            border-bottom: $light-gray-rgba;
          }
          &.mdc-notched-outline--notched {
            .mdc-notched-outline__notch {
              border-top: none;
            }
          }
        }
      }
    }
  }
  >.mat-mdc-form-field:not(.mat-form-field-disabled):not(.mat-form-field-invalid):not(.no-hover):not(.thin-hover) {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        &:hover {
          .mdc-notched-outline {
            margin-top: -1px;

            .mdc-notched-outline__leading,
            .mdc-notched-outline__trailing {
              border-top: 2px solid $color-primary-500;
              border-bottom: 2px solid $color-primary-500;
            }
            .mdc-notched-outline__leading {
              border-left: 2px solid $color-primary-500;
            }
            .mdc-notched-outline__trailing {
              border-right: 2px solid $color-primary-500;
            }
            .mdc-notched-outline__notch {
              border-top: 2px solid $color-primary-500;
              border-bottom: 2px solid $color-primary-500;

              .mdc-floating-label--float-above {
                margin-top: 1px;
              }
            }
            &.mdc-notched-outline--notched {
              .mdc-notched-outline__notch {
                border-top: none;
              }
            }
          }
        }
      }
    }
  }
  >.mat-mdc-form-field.thin-hover:not(.mat-form-field-disabled):not(.mat-form-field-invalid):not(.no-hover) {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        &:hover {
          .mdc-notched-outline {
            .mdc-notched-outline__leading,
            .mdc-notched-outline__trailing {
              border-top: 1px solid $color-primary-500;
              border-bottom: 1px solid $color-primary-500;
            }
            .mdc-notched-outline__leading {
              border-left: 1px solid $color-primary-500;
            }
            .mdc-notched-outline__trailing {
              border-right: 1px solid $color-primary-500;
            }
            .mdc-notched-outline__notch {
              border-top: 1px solid $color-primary-500;
              border-bottom: 1px solid $color-primary-500;

              .mdc-floating-label--float-above {
                margin-top: 1px;
              }
            }
            &.mdc-notched-outline--notched {
              .mdc-notched-outline__notch {
                border-top: none;
              }
            }
          }
        }
      }
    }
  }
}
