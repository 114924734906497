/* Font */
$font-family: Inter, 'Source Sans Pro', sans-serif;
$root-font-size: 16px;
$light-font: 300;
$regular-font: 400;
$semi-bold-font: 600;
$bold-font: 700;

/* Color Palette */
$color-primary-900: #005f00; // Style Guide
$color-primary-800: #007020; // Style Guide
$color-primary-700: #008024; // Style Guide
$color-primary-600: #009029; // Style Guide
$color-primary-500: #00a12e; // Style Guide
$color-primary-400: #32b357; // Style Guide
$color-primary-300: #66c681; // Style Guide
$color-primary-200: #99d9ab; // Style Guide
$color-primary-100: #ccecd5; // Style Guide
$color-primary-50: #E4F5EA;  // Style Guide

/* Most used colors */
$white: #FFFFFF;
$black: #000000;
$hover-on-white: #EEEFF2;

$dark-green: $color-primary-700;
$green: $color-primary-500;
$light-green: $color-primary-300;

$dark-red: #BF1010; // Style Guide
$error: #D92121; // Style Guide
$disabled-red: #F4BCBC; // Style Guide
$disabled-green: #99d9ab; // Style Guide
$light-red: #F63F32;
$task-red: #f42808;
$dark-orange: #B77B30;
$alert-orange: #e06a03;
$orange: #EB7D3C;
$light-orange: #FCC175;
$divider-orange: #FCC26C;
$pending-orange: #e05a00;
$light-blue: #d8e9ff;

/* Gray Palette */
$light-gray: #D8E0E5;
$pale-gray: #f8f8fa; // Style Guide
$gray-background-color: #f3f3f3;
$gainsboro-gray: #e0e0e0;
$disabled-gray: #b9bec6;
$bg-gray: #e1e4e8;
$header-border-gray: #bfbfbf;
$bg-search: #F9FAFA;
$light-gray-50: #E9EAED;
$progress-gray: #d4d4d4;
$gray-26: #cdcdcd;
$table-border-gray: #c5cad0;
$complex-dropdown-grey: #f8f8f8;
$item-gray: #DDDFE1;
$label-gray: #EDEDED;
$gray-system: #fafafa;
$gray-1: #DDDDDD;
$gray-2: #f4f4f4;
$gray-3: #EEEEEE;
$gray-16: #F7F8F8;
$gray-8: #F7FAFC;
$body-background-color: #edeff2;
$gray-10: #F3F4F9;
$gray-14: #CCCCCC;
$gray-18: #E0E1E4;
$gray-17: #fbf9f4;
$pale-gray-two: #f6f7f9; // Style Guide
$gray-19: #fbfcfe;
$gray-20: #eaeaeb;
$light-grey-blue-tint: #e8eaed; // Style Guide
$gray-25: #f8f8f8;
$gray-28: #f3f5f6;
$gray-33: #e6e6e6;
$light-gray-rgba: solid 1px rgba(0, 0, 0, 0.12);
$dark-gray-rgba: rgba(0, 0, 0, 0.87);
$gray-4: #212121;
$gray-9: #222222;
$gray-11: #1B2129;
$gray-13: #292929;
$charcoal-grey: #2C3137; // Style Guide
$gray-23: #3c3c3c;
$dark-gray-medium: #393f46;
$gray-30: #444444;
$cancel-gray: #454b54;
$gray-7: #45484d;
$gray-24: #606060;
$gray-66: #666666;
$gray-12: #505a68;
$review-dropdown-grey: #69727e;
$dark-petrol-grey: #525a66; // Style Guide
$gray-32: #586272;
$mid-gray: #6f7681; // Style Guide
$dark-gray: #667080;
$gray-29: #666f80;
$gray-15: #758091;
$gray-hour: #959da9;
$sidemenu-gray: #8a93a0; // Style Guide
$warm-grey: #9e9e9e; // Style Guide
$gray: #959aa1;
$gray-21: #8c96a6;
$gray-27: #8696ac;
$gray-22: #9EA2D5;

$blue-hover: #0086dc; // Style Guide
$blue-1: #404CB0;
$blue-2: #2232ff;
$yellow-1: #fac710;
$yellow-2: #c9ab3d;
$yellow-3: #f8e99d;
$yellow-4: #fffc9f;
$yellow-5: #e3e3d9;
$green-1: #8EC359;
$green-2: #09A88D;

$label-rgba: rgba(0, 0, 0, 0.38);
$label-light-rgba: rgba(0, 0, 0, 0.6);
$label-email: #2b323b;

$review-comment: #FCF3A0;
$review-comment-selected: #FAC26C;
$review-comment-box-selected-border: #FAC26C;
$review-highlight: #bad7fb;
$review-highlight-selected: #8FC1FF;
$review-hover: #04A12E;
$notification-hover: #ECEFF2;
$review-gray: rgba(33, 33, 33, 0.08);
$sdox-section-text: #2d3239;
$tooltip-bg: #2C3036; // Style Guide
$dark-black: #15181C;

/* Component colors */
$background-green: #09e047;
$ecm-border-color: #0060f0;
$ilm-border-color: #e05a00;
$sdox-border-color: #0093ad;
$ultra-light-green: #d2eeda;
$ultra-light-green-2: #e5f5ea;
$alert-bg: #F5BCBD;

/* Component spacing/dimensions */
$button-border-radius: 4px;
$button-border-width: 2px;
$button-padding: 0 8px;
$button-padding-medium: 0 15px;
$button-padding-large: 0 30px;
$panel-border-radius: 2px;
$fe-horizontal-padding: 20px;
$fe-bottom-padding: 30px;

$card-shadow: rgba(138, 138, 138, 0.24);
$card-shadow-hover: rgba(0, 0, 0, 0.2);

$table-cell-y-padding: 14px;
$table-cell-x-padding: 14px;

$gray-blue: #8596ad; // Style Guide

$menu-width: 3.75rem;
$menu-opened-width: 12.375rem;

$canvas-height-with-border: 65px;
$footer-height-with-border: 31px;
$right-content-header: 40px;
$pdf-download-banner-height: 32px;
$settings-sidenav-width: 510px;