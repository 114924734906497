@import "abstracts/variables";

.mat-paginator {
    background: transparent;
    letter-spacing: inherit;

    .mat-mdc-paginator-container {
        color: $dark-petrol-grey;
        font-weight: $semi-bold-font;
        padding: 0 24px;
        min-height: 50px;
        height: 50px;
        flex-wrap: nowrap;

        .mat-mdc-paginator-page-size {
            margin-right: 0;
            margin-top: 5px;

            .mat-mdc-paginator-page-size-label {
                margin-right: 2px;
            }
        }

        .mat-mdc-form-field {
            &.mat-mdc-paginator-page-size-select {
                width: 35px;

                .mat-mdc-text-field-wrapper {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }

                .mdc-notched-outline {
                    background-color: transparent;
                    border: none;

                    > * {
                        border: none;
                    }
                }

                .mat-mdc-form-field-infix {
                    padding-top: 6px;
                }

                .mat-mdc-select {
                    .mat-mdc-select-value {
                        color: $charcoal-grey;
                        font-weight: $semi-bold-font;
                        text-align: center;
                    }
                    .mat-mdc-select-arrow-wrapper {
                        .mat-mdc-select-arrow {
                            color: $charcoal-grey;
                        }
                    }
                }
            }
        }

        .mat-mdc-form-field-appearance-legacy {
            .mat-mdc-form-field-underline {
                height: 0;
            }
        }

        .mat-mdc-paginator-range-actions {
            margin-left: 10px;

            .mat-mdc-paginator-range-label {
                margin: 0 24px 0 0;
            }
            .mat-mdc-icon-button {
                width: 20px;
                height: 20px;
                line-height: 20px;
                color: $charcoal-grey;
                padding: 0 !important;
                &.mat-mdc-button-disabled {
                    color: $warm-grey;
                }
                .mat-mdc-paginator-icon {
                    width: 20px;
                    height: 20px;
                }

                .mat-mdc-button-touch-target {
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                }
            }
            button:nth-of-type(2) {
                margin-right: 8px;
            }
        }
    }
}
