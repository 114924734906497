.vertical-filters-wrapper {
  width: 100%;
  .active-filter {
    margin-right: 20px;
    margin-top: 10px;
    padding-bottom: 10px;
    min-width: 235px;
  }
  &.space-right-8 {
    .active-filter {
      margin-right: 8px;
    }
  }

  .save-view-container {
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding-bottom: 10px;
    flex-grow: 1;
  }

}
.horizontal-filters-wrapper {
  width: 100%;
  app-filters-dropdown {
    margin-right: 20px;
    padding-bottom: 10px;
  }
  .active-filter {
    margin-right: 20px;
    padding-bottom: 10px;
    min-width: 235px;
  }
}

