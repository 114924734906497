@mixin app-text-style(
  $font-size,
  $line-height,
  $letter-spacing,
  $font-weight: normal,
  $font-style: normal,
  $font-stretch: normal
) {
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  font-style: $font-style;
  font-stretch: $font-stretch;
}

@mixin app-box-shadow(
  $offset-x: 10px,
  $offset-y: 10px,
  $blur-radius: 20px,
  $spread-radius: 0,
  $error: 21,
  $green: 24,
  $blue: 28,
  $alpha: 0.17
) {
  box-shadow: $offset-x $offset-y $blur-radius $spread-radius rgba($error, $green, $blue, $alpha);
  -webkit-box-shadow: $offset-x $offset-y $blur-radius $spread-radius rgba($error, $green, $blue, $alpha);
  -moz-box-shadow: $offset-x $offset-y $blur-radius $spread-radius rgba($error, $green, $blue, $alpha);
}
@mixin comment-box-shadow($color: transparent, $width: 1px) {
  border: $width solid $color;
  box-shadow: 0 1px 4px 0 rgba(138, 138, 138, 0.24);
}

@mixin actions-button(
  $color,
  $border-color,
  $background-color,
  $icon-color: #FFFFFF
) {
  color: $color;
  border-color: $border-color;
  background-color: $background-color;
  .icon {
    color: $icon-color;
  }
}

@mixin inter-semibold {
  font-family: $font-family;
  font-weight: 600!important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
}

@mixin inter-regular {
  font-family: $font-family;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal!important;
  line-height: 1.67;
}

@mixin line-clamp($line-clamp: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: $line-clamp;
  -webkit-box-orient: vertical;
}

@mixin flex-center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin integration-step-title($marginBottom: 24px) {
  color: $charcoal-grey;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.4px;
  font-weight: $semi-bold-font;
  margin-bottom: $marginBottom;
}

@mixin flex-calc-min-width($percentage, $substractedValue) {
  display: flex;
  min-width: calc($percentage - $substractedValue);
  flex: 1 1 calc($percentage - $substractedValue);
}
