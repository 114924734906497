@import "abstracts/variables";
.modal-subtitle {
  display: inline-block;
  padding-left: 8px;
  font-size: 16px;
  font-weight: normal;
  color: $sidemenu-gray;
}

.full-height-modal {
  height: calc(100vh - 64px); // 64px = viewport padding top + padding bottom
  .btr-modal-container {
    height: 100%;
  }
  &.multi-step-modal {
    mat-dialog-content {
      max-height: calc(100vh - 64px - 60px - 60px);
    }
  }
}

.multi-step-modal {
  &:not(.full-height-modal) {
    max-height: 80vh;
    mat-dialog-content {
      max-height: calc(80vh - 60px - 60px);
    }
  }
}

.full-width-modal {
  .mat-mdc-dialog-container {
    .btr-modal-container {
      mat-dialog-content {
        padding: 0;
      }
    }
  }
}

.mat-mdc-dialog-container {
  padding: 0 !important;
  overflow: hidden !important;

  .btr-modal-container {
    position: relative;

    [mat-dialog-title] {
      font-family: $font-family;
      font-size: 16px;
      font-weight: 600 !important;
      line-height: 22px;
      color: $gray-4;
      padding: 19px 24px;
      border-bottom: 1px solid $label-gray;
      margin: 0;
      height: 60px;
      min-height: 60px;
      max-height: 60px;
    }
    mat-dialog-content {
      padding: 24px;
      margin: 0px;
      height: calc(100% - 60px - 60px); // 100% - header - footer
      min-height: calc(100% - 60px - 60px); // 100% - header - footer
      &.padding-bottom-5 {
        padding-bottom: 5px;
      }
      mat-form-field.btr-form-field {
        &.no-margin-bottom {
          margin-bottom: 0;
        }
      }
    }
    mat-dialog-actions {
      display: flex;
      justify-content: flex-end;
      box-sizing: border-box;
      padding: 8px 24px;
      margin: 0;
      height: 60px;
      min-height: 60px;
      max-height: 60px;
      border-top: 1px solid $label-gray;
      &.space-between {
        justify-content: space-between;
      }
    }

    .section {
      .section-title {
        margin-bottom: 1.34375em;
        font-weight: bold;
      }
    }
  }

  .multi-step-modal-display {
    display: none;

    &.show {
      display: block;
    }
  }
}

.mat-mdc-dialog-container .mdc-dialog__container .mdc-dialog__content {
  color: #000000de;
}