@import "abstracts/mixins";
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,300,600,700,900");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@font-face {
  font-family: Inter;
  src: local(Inter-Regular),
  url('../../assets/fonts/Inter-Regular.ttf') format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: Inter;
  src: local(Inter-Medium),
  url('../../assets/fonts/Inter-Medium.ttf') format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Inter;
  src: local(Inter-SemiBold),
  url('../../assets/fonts/Inter-SemiBold.ttf') format("truetype");
  font-weight: 600;
}

/*
* Cross-browser alternative to -webkit-text-security
* https://www.npmjs.com/package/text-security;
*/
@font-face {
  font-family: 'text-security-disc';
  src: url('../../assets/fonts/text-security-disc.eot');
  src: url('../../assets/fonts/text-security-disc.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/text-security-disc.woff2') format('woff2'),
  url('../../assets/fonts/text-security-disc.woff') format('woff'),
  url('../../assets/fonts/text-security-disc.ttf') format('truetype'),
  url('../../assets/fonts/text-security-disc.svg#text-security') format('svg');
}

.body-1 {
  @include app-text-style(1rem, 1.63, -0.2px);
}

.body-2 {
  @include app-text-style(0.875rem, 1.43rem, -0.4px);
}

.body-link {
  @include app-text-style(0.875rem, 1.43rem, -0.4px, 600);
}

.body-3 {
  @include app-text-style(0.75rem, 1.67, -0.2px);
}

.headline-1 {
  @include app-text-style(4.5rem, 1.11, -3px, 600);
}

.headline-2 {
  @include app-text-style(3.5rem, 1.11, -2.4px, 600);
}

.headline-3 {
  @include app-text-style(2.25rem, 1.36, -1.5px, 600);
}

.headline-4 {
  @include app-text-style(1.688rem, 1.52, -1px, 600);
}

.headline-5 {
  @include app-text-style(1.375rem, 1.36, -0.55px, 600);
}

.headline-6 {
  @include app-text-style(1.25rem, 1.4, -0.5px, 600);
}

.subtitle-1 {
  @include app-text-style(1.125rem, 1.22, -0.4px, 600);
}

.subtitle-2 {
  @include app-text-style(1rem, 1.38, -0.4px, 600);
}

.overline-normal {
  @include app-text-style(0.625rem, 1.5, -0.2px, 400);
}

.overline {
  @include app-text-style(0.625rem, 1.6, 1.5px, 500);
}

.overline-2 {
  @include app-text-style(0.625rem, 1.6, 1px, 500);
}

.caption {
  @include app-text-style(0.75rem, 1.67, -0.2px, 600);
}

.caption-normal {
  @include app-text-style(0.75rem, 1.67, -0.2px, 300);
}

.accordion-title {
  @include app-text-style(0.75rem, 1.67, -0.2px, 600);
}

/* Text utilities */

.text-color-primary-900 {
  color: $color-primary-900;
}

.text-color-primary-800 {
  color: $color-primary-800;
}

.text-color-primary-700 {
  color: $color-primary-700;
}

.text-color-primary-600 {
  color: $color-primary-600;
}

.text-color-primary-500 {
  color: $color-primary-500;
}

.text-color-primary-400 {
  color: $color-primary-400;
}

.text-color-primary-300 {
  color: $color-primary-300;
}

.text-color-primary-200 {
  color: $color-primary-200;
}

.text-color-primary-100 {
  color: $color-primary-100;
}

.text-color-primary-50 {
  color: $color-primary-50;
}

.bold-text {
  font-weight: 600;
  * {
    font-weight: 600;
  }
}

.normal-text {
  font-weight: 400;
}

.italic-text {
  font-style: italic;
}

.orange-text {
  color: $orange;
}

.gray-text {
  color: $dark-gray;
}

.capitalize {
  text-transform: capitalize;
}

.ellipsis-text {
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.break-word {
  overflow-wrap: break-word;
}

.password-input {
  font-family: text-security-disc;
  -webkit-text-security: disc;
}

.big-text {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
}

.confirmation-red {
  font-size: 14px;
  font-weight: 600;
  color: $error;
}

.pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}
