.links-wrapper {
  letter-spacing: normal;

  &.secondary {
    .link-section {
      background-color: unset;
      padding: 2px 0 0 0;

      .link-line {
        font-size: 12px;
        line-height: 1.67;
        display: inline-block;

        .link-line-details {
          .link-icon {
            font-size: 9px;
          }

          .link-value {
            color: $color-primary-500;
          }
        }
      }

      .section-row-name {
        font-size: 12px;
        line-height: 1.67;
        color: $gray-7;
        font-weight: $semi-bold-font;
      }
    }
  }
}

.link-section {
    background-color: $pale-gray-two;
    padding: 4px 2px 4px 12px;
    margin-bottom: 8px;
    border-radius: 4px;

    .link-line {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.4px;
        font-weight: $semi-bold-font;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .link-line-details {
            .link-icon {
                color: $color-primary-500;
                font-size: 12px;
            }

            .link-label {
                margin-left: 8px;
                color: $gray-66;
            }

            .link-value {
                color: $gray-7;
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .link-line-buttons {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;
            line-height: 20px;
            color: $gray-66;

            button {
                padding: 0 10px;
                font-weight: $semi-bold-font;
                .mdc-button__label {
                    font-size: 12px;

                    @include flex-center;
                    .icon {
                        margin-right: 2px;
                        padding: 5px 5px 5px 0;
                    }
                }
                .mat-mdc-button-focus-overlay {
                    display: none;
                }

                &.link-edit:hover {
                    &:not(:disabled) {
                        @include actions-button($color-primary-700, transparent, transparent, $color-primary-700);
                    }
                }

                &.link-remove:hover {
                    &:not(:disabled) {
                        @include actions-button($dark-red, transparent, transparent, $dark-red);
                    }
                }
            }

            .link-edit {
                .icon-n-edit {
                    color: $color-primary-500;
                }
            }

            .link-remove {
                .icon-n-delete {
                    color: $error;
                }
            }
        }
    }

    .link-rows {
        ul {
            padding: 0 16px 0 30px;
            margin: 8px 0;
            list-style-type: none;

            li {
                color: $gray-66;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: -0.4px;
                margin-bottom: 8px;
                display: flex;

                span.bullet {
                   margin-right: 8px;
                }

                span.section-row-content {
                    @include line-clamp();

                    .section-row-name {
                        font-weight: $semi-bold-font;
                    }
                }
            }
        }
    }
}
