* {
  font-family: Inter, 'Source Sans Pro', sans-serif;
}

html {
  font-size: $root-font-size;
  height: 100%;
}

body {
  font-family: Inter, 'Source Sans Pro', sans-serif;
  font-weight: 300;
  box-sizing: border-box;
  margin: 0;
  background-color: $body-background-color;
  counter-reset: section;
  height: 100%;
}

/*
* Fix the table full width on Firefox
*/
table {
  width: 100%;
}

[contenteditable]:focus {
  outline: 0px solid transparent;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.progress-bar-container {
  height: 2px;
}

// Fixing NRESQ-6581:
body > .e-diagram-tooltip {
  // Comment below line and enable the others if tooltips are needed on FE's on Canvas.
  display: none;
  // background-color: #ffffff;
  // padding: 2px 5px;
  // font-size: 14px;
}
