
.menu-tooltip {
  background-color: $tooltip-bg;
  color: white;
  font-family: Inter, sans-serif;
}

.multiline-tooltip {
  white-space: pre-line;
}
