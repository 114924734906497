
.mat-mdc-radio-button.mat-mdc-accent.mat-mdc-radio-checked .mat-mdc-radio-outer-circle {
  border-color: #00a12e;
}

.mat-mdc-radio-button.mat-mdc-accent .mat-mdc-radio-inner-circle,
.mat-mdc-radio-button.mat-mdc-accent .mat-mdc-radio-ripple .mat-mdc-ripple-element:not(.mat-mdc-radio-persistent-ripple),
.mat-mdc-radio-button.mat-mdc-accent.mat-mdc-radio-checked .mat-mdc-radio-persistent-ripple,
.mat-mdc-radio-button.mat-mdc-accent:active .mat-mdc-radio-persistent-ripple {
  background-color: #00a12e;
}

.mat-mdc-radio-button.mat-mdc-accent.mat-mdc-radio-checked.white-background .mat-mdc-radio-outer-circle {
  border-color: $white;
}

.mat-mdc-radio-button.mat-mdc-accent.white-background .mat-mdc-radio-inner-circle,
.mat-mdc-radio-button.mat-mdc-accent.white-background .mat-mdc-radio-ripple .mat-mdc-ripple-element:not(.mat-mdc-radio-persistent-ripple),
.mat-mdc-radio-button.mat-mdc-accent.mat-mdc-radio-checked.white-background .mat-mdc-radio-persistent-ripple,
.mat-mdc-radio-button.mat-mdc-accent.white-background:active .mat-mdc-radio-persistent-ripple {
  background-color: $white;
}
