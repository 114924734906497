@import "abstracts/variables";

amplify-authenticator {
  [data-amplify-authenticator] {
    display: block;
    [data-amplify-container] {
      width: auto;

      [data-amplify-router] {
        background-color: transparent;
        box-shadow: none;
        border-width: 0;

        [data-amplify-form] {
          padding: 0 60px;
        }
        [data-amplify-footer] {
          display: none;
        }
      }
    }
  }

  amplify-form-field[name="confirmation_code"] {
    padding-bottom: 16px;
    border-bottom: 1px solid $gray-3;
    margin-bottom: 8px;
  }

  // amplify-form-field[name="confirm_password"] {
  //   height: 68px;
  // }

  .amplify-field {
    margin-bottom: 5px;
    font-size: 14px;
    gap: 5px;

    .amplify-field-group__outer-end {
      display: none;
    }
  }

  .amplify-button[data-variation="primary"] {
    background: $color-primary-500;
    font-weight: $semi-bold-font !important;
    margin-bottom: 20px;
    font-size: 14px;
    letter-spacing: normal;
    line-height: 20px;
    border: none;

    &.amplify-button--disabled {
      color: $white;
    }

    &:hover {
      background: $color-primary-700;
    }
  }

  h3.amplify-heading {
    display: none;
  }

  .amplify-input {
    padding: 7px 16px;
    border: $light-gray-rgba;
  }

  amplify-text-field {
    display: block !important;
    position: relative;
  }

  .amplify-label {
    display: none;
  }

  amplify-text-field:focus-within, amplify-password-field:focus-within {
    position: relative;

    input::placeholder {
      color: transparent;
    }

   .amplify-label {
      display: block;
      position: absolute;
      z-index: 10;
      width: auto;
      height: auto;
      padding: 0 5px;
      margin: auto auto auto 7px;
      overflow: hidden;
      clip: auto;
      white-space: nowrap;
      border-width: 0;
      top: -10px;
      color: rgba(0, 0, 0, 0.6);
      font-family: Inter, "Source Sans Pro", sans-serif;
      font-size: 13px;
      background-color: $white;
    }
  }

  .amplify-button[data-size="small"] {
    display: none;
  }

  ::placeholder {
    color: $warm-grey;
    font-weight: $semi-bold-font;
  }
}

button[type="submit"].amplify-button {
  order: 6
}

// error for a specific field
.amplify-flex.amplify-field {
  amplify-error {
    div[data-variation="error"] {
      margin: 16px 0 0;
    }
  }
}

amplify-error {
  order: 5;

  div[data-variation="error"] {
    margin: 0 0 8px;
  }

  .amplify-alert[data-variation=error] {
    color: $dark-red;
    background-color: transparent;
    padding: 0;

    .amplify-icon {
      display: none;
    }

    div {
      font-size: 12px;
      line-height: 20px;
    }

    .amplify-button[data-variation=link] {
      display: none;
    }
  }
}

// NRESQ-5706 Remove arrows from input type="number"
/* Chrome, Safari, Edge, Opera */
input[name="confirmation_code"]::-webkit-outer-spin-button,
input[name="confirmation_code"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[name="confirmation_code"][type=number] {
  -moz-appearance: textfield;
}
