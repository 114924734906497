@use "@angular/material" as mat;
@import "abstracts/mixins";

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

@import "./styles/abstracts/variables";

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$resq-green: (
  50: #f2f9eb,
  100: #dff0cd,
  200: #cae7ac,
  300: #b4dd8a,
  400: #a4d571,
  500: #94ce58,
  600: #8cc950,
  700: #81c247,
  800: #77bc3d,
  900: #65b02d,
  A100: #f8fff3,
  A200: #daffc0,
  A400: #bbff8d,
  A700: #acff74,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$resq-red: (
  50: #f63f32,
  100: #f63f32,
  200: #f63f32,
  300: #f42808,
  400: #f42808,
  500: #f42808,
  600: #d92121,
  700: #d92121,
  800: #d92121,
  900: #bf1010,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// Define Colors:
$resq-theme-primary: mat.define-palette(mat.$gray-palette);
$resq-theme-accent: mat.define-palette($resq-green);
$resq-theme-warn: mat.define-palette($resq-red, 800);

// Define Typography:
$resq-body-1: mat.define-typography-level(
  app-text-style(1rem, 1.63, -0.2px)
);
$resq-body-2: mat.define-typography-level(
  app-text-style(0.875rem, 1.43rem, -0.4px)
);
$resq-headline-1: mat.define-typography-level(
  app-text-style(4.5rem, 1.11, -3px, 600)
);
$resq-headline-2: mat.define-typography-level(
  app-text-style(3.5rem, 1.11, -2.4px, 600)
);
$resq-headline-3: mat.define-typography-level(
  app-text-style(2.25rem, 1.36, -1.5px, 600)
);
$resq-headline-4: mat.define-typography-level(
  app-text-style(1.688rem, 1.52, -1px, 600)
);
$resq-headline-5: mat.define-typography-level(
  app-text-style(1.375rem, 1.36, -0.55px, 600)
);
$resq-headline-6: mat.define-typography-level(
  app-text-style(1.25rem, 1.4, -0.5px, 600)
);
$resq-subtitle-1: mat.define-typography-level(
  app-text-style(1.125rem, 1.22, -0.4px, 600)
);
$resq-subtitle-2: mat.define-typography-level(
  app-text-style(1rem, 1.38, -0.4px, 600)
);
$resq-caption: mat.define-typography-level(
  app-text-style(0.75rem, 1.67, -0.2px, 600)
);
$resq-button: mat.define-typography-level(
  app-text-style(1rem, 1.38, normal, 600)
);


$resq-typography: mat.define-typography-config(
  $body-1: $resq-body-1,
  $body-2: $resq-body-2,
  $headline-1: $resq-headline-1,
  $headline-2: $resq-headline-2,
  $headline-3: $resq-headline-3,
  $headline-4: $resq-headline-4,
  $headline-5: $resq-headline-5,
  $headline-6: $resq-headline-6,
  $subtitle-1: $resq-subtitle-1,
  $subtitle-2: $resq-subtitle-2,
  $caption: $resq-caption,
  $button: $resq-button,
);

$resq-theme: mat.define-light-theme(
  (
    color: (
      primary: $resq-theme-primary,
      accent: $resq-theme-accent,
      warn: $resq-theme-warn,
    ),
    typography: $resq-typography,
  )
);

@include mat.all-component-themes($resq-theme);

// Overwriting Angular Material CSS variables
:root {
  --mat-option-label-text-size: 16px;
  --mdc-checkbox-selected-checkmark-color: #{$white};
  --mat-expansion-header-expanded-state-height: 48px;
  --mat-expansion-container-background-color: transparent;
  --mdc-plain-tooltip-container-color: #{$tooltip-bg};
  --mdc-plain-tooltip-supporting-text-weight: #{$semi-bold-font};
  --mdc-plain-tooltip-supporting-text-line-height: 1.67;
  --mdc-checkbox-state-layer-size: 18px;
  --mdc-checkbox-selected-icon-color: #{$color-primary-500};
  --mdc-checkbox-selected-focus-icon-color: #{$color-primary-500};
  --mat-radio-checked-ripple-color: #{$color-primary-500};
  --mdc-radio-selected-icon-color: #{$color-primary-500};
  --mat-paginator-container-text-size: 12px;
  --mdc-switch-unselected-track-color: #{$warm-grey};
  --mdc-switch-unselected-hover-track-color: #{$warm-grey};
  --mdc-switch-unselected-handle-color: #{$color-primary-500};
  --mdc-switch-selected-handle-color: #{$color-primary-500};
  --mdc-switch-selected-hover-handle-color: #{$color-primary-500};
  --mdc-icon-button-state-layer-size: 24px;
  --mat-expansion-header-hover-state-layer-color: transparent;
  --mdc-radio-selected-hover-icon-color: #{$color-primary-500};
}
