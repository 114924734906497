
mat-tab-group.primary {
  .mat-mdc-tab-labels {
    .mat-mdc-tab {
      height: 36px;
      min-width: 40px;
      font-weight: 600;
      border: solid 1px #e0e0e0;
      opacity: 1;
      flex: 1;
      font-size: 12px;
      line-height: 1.67;
      letter-spacing: -0.2px;
      color: $dark-petrol-grey;
  
      &:first-of-type {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
  
      &:last-of-type {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      &.mdc-tab--active {
        background-color: $color-primary-500;
        .mdc-tab__text-label {
          color: #FFFFFF;
        }
      }
    }
  }

  .mat-mdc-tab-header {
    margin-bottom: 16px;
  }

  .mat-mdc-tab-nav-bar, .mat-mdc-tab-header {
    border-bottom: 0;
  }
  .mat-mdc-tab-body-wrapper {
    border-top: 1px solid $gainsboro-gray;
    padding: 13px 0 0 0;
  }

  .mat-mdc-ink-bar, .mat-mdc-tab-nav-bar.mat-primary .mat-mdc-ink-bar {
    background-color: unset !important;
  }
}

.mat-mdc-tab-label-container {
  .mat-mdc-tab-list {
    .mdc-tab-indicator {
      display: none;
    }
  }
}