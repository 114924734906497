mat-tooltip-component {
  .mdc-tooltip {
    .mdc-tooltip__surface {
        font-size: 12px;
    }
  }
}

.mdc-tooltip.multi-line-tooltip {
  white-space: pre-line !important;
}