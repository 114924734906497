/* Material UI general customisation */
@import "abstracts/variables";
@import "abstracts/mixins";

.mat-mdc-menu-panel {
  &.btr-user-dropdown {
    width: 450px;
    margin-top: -26px !important;

    .mat-mdc-menu-content {
      max-width: unset !important;
    }

    .mat-mdc-form-field-prefix {
      top: 0.5em;
    }

    .mat-mdc-form-field-infix {
      padding: 0.1rem 5px 0.75em;
    }

    ::placeholder {
      color: rgba(0, 0, 0, 0.38);
      @include app-text-style(0.875rem, 1.43rem, -0.4px, 600);
    }
  }

  &.btr-user-search {
    width: 415px;
    margin-top: 0px;
  }

  &.btr-dropdown.dropdown-button-menu {
    min-width: 9.375rem;
    margin-top: 9px;

    &.review-versions-dropdown {
      min-width: 200px;
      margin-top: 2px;

      .action-button {
        @include actions-button($review-dropdown-grey, $gray-2, $gray-2);

        &.primary {
          @include actions-button($white, $color-primary-500, $color-primary-500);

          &:hover {
            @include actions-button($white, $color-primary-700, $color-primary-700);
          }

          .category-info {
            color: $white;
          }
        }

        .category-title {
          font-weight: $semi-bold-font;
        }

        .category-info {
          font-size: 10px;
          color: $review-dropdown-grey;
        }

        &:hover {
          @include actions-button($review-dropdown-grey, $hover-on-white, $hover-on-white);
        }
      }
    }

    .submenu-button {
      &.selected {
        background-color: $light-gray;
      }
    }

    .action-button-details {
      color: $gray-66;
      max-width: 270px;
      white-space: break-spaces;
      padding: 14px 16px !important;

      &:hover {
        cursor: default;
        background: unset;
      }
    }

    .action-button-container {
      &:not(:last-child) {
        border-bottom: 1px solid $gray-3;
      }
    }

    .action-button {
      .category-title {
        line-height: normal;
        display: block;
        letter-spacing: normal;

        &.disabled {
          opacity: 0.5;
        }
      }

      &.disabled {
        pointer-events: all;
      }

      .category-info {
        font-size: 0.875rem;
        line-height: normal;
        display: block;
        color: $black;
      }

      &.mat-mdc-menu-item[disabled] {
        color: $disabled-gray;
        background-color: $gray-3;
        pointer-events: all;
        opacity: 1;

        .mat-mdc-menu-submenu-icon {
          opacity: 0.38;
        }
      }

      .action-icon {
        margin-right: 20px;
        font-size: 18px;

        &.primary-500 {
          color: $color-primary-500;
        }

        &.dark-gray {
          color: $dark-petrol-grey;
        }
      }

      .mat-mdc-menu-item-text {
        .category-info {
          font-size: 12px;
          margin-top: 2px;
          margin-bottom: -2px;
        }
      }
    }
  }
}

.mat-mdc-menu-panel.btr-basic {
  overflow-x: hidden;
  min-height: auto;
  min-width: auto;
  max-width: unset;
  border-radius: $panel-border-radius;
  box-shadow:
    0 3px 5px 0 rgba(0, 0, 0, 0.15),
    0 5px 15px 0 rgba(0, 0, 0, 0.1);

  .mat-mdc-menu-content:not(:empty) {
    padding-top: 0;
    padding-bottom: 0;
    display: block !important; // [NRESQ-11954] We need to overwrite a 'flex' display which is inserted when the the Actions menu is opened before the data is loaded
  }

  .mat-mdc-menu-content {
    button.mat-mdc-menu-item {
      font-family: inherit;
      line-height: 1rem;
      height: 3rem;

      &:not(:last-child) {
        border-bottom: 1px solid $body-background-color;
      }

      &.caption-normal {
        .mat-mdc-menu-item-text {
          font-size: 0.75rem;
          line-height: 16px;
          text-wrap: wrap;
        }
      }
    }
  }
}

mat-form-field {
  width: 100%;
}

.mat-mdc-header-cell {
  font-size: 13px;
  font-weight: 600 !important;
}

.mat-mdc-radio-outer-circle {
  border-color: #586272;
}

// Accordion
mat-accordion.workflow-accordion {
  >.mat-expansion-panel {
    >.mat-expansion-panel-content {
      >.mat-expansion-panel-body {
        padding: 0;
      }
    }
  }

  .mat-expansion-panel {
    margin-bottom: 20px;
    border-radius: 0 !important;
    box-shadow: none;
    background: unset;
    @include app-box-shadow(0, 2px, 2px, 0, 0, 0, 0, 0.11);

    &.accordion-completer-hidden {
      display: none;
    }

    .mat-expansion-panel-header {
      height: 50px;
      border-radius: 0;
      font-weight: bold;
      background-color: #2c3036;
      padding-left: 20px;
      line-height: 50px;
      min-height: 50px;

      &:hover {
        background-color: #2c3036;
      }

      >.mat-expansion-indicator:after {
        color: $color-primary-500;
      }

      .mat-expansion-panel-header-title {
        color: $white;
        justify-content: space-between;

        .info-accordion-item-title {
          position: relative;

          .workflow-item-title {
            padding-left: 35px;
            color: white;
            position: relative;
            overflow-x: auto;
            overflow-y: hidden;
            white-space: nowrap;

            &.step-menu-trigger {
              color: $green;
              cursor: pointer;
              vertical-align: middle;
            }

            &.step-menu-trigger.disabled {
              color: $light-gray;
            }

            .progress {
              color: $disabled-gray;
            }
          }

          .step-index {
            position: absolute;
            left: 0px;
            top: 2px;
            width: 20px;
            height: 20px;
            color: white;
            line-height: 1.6;
            border: 2px solid white;
            border-radius: 50%;
            background-color: white;
            text-align: center;
            z-index: 33;

            span {
              mat-icon {
                font-size: 17px;
                width: 17px;
                height: 17px;
                line-height: 20px;
              }
            }
          }
        }
      }
    }

    &[class*="-completer"] {
      .mat-expansion-panel-header {
        display: none;
      }

      .content {
        padding: 20px;
      }

      .mat-expansion-panel-body {
        background-color: $white;
        padding: 0;
        border-bottom-width: 0;
      }
    }

    &[class*="-hidden"] {
      display: none;
    }

    &[class*="-is_current"] {
      .mat-expansion-panel-header {
        &:hover {
          background-color: #15181a !important;
        }

        >.mat-expansion-indicator:after {
          color: $color-primary-500;
        }

        .mat-expansion-panel-header-title {
          .info-accordion-item-title {
            .step-index {
              border: 2px solid white;
              background-color: $tooltip-bg;
              color: white;
            }
          }
        }
      }
    }

    &[class*="-is_unreached"],
    &.disabled {
      .mat-expansion-panel-header {
        color: #b9bec6;
        background-color: #eeeff2;

        >.mat-expansion-indicator:after {
          color: #b9bec6;
        }

        .mat-expansion-panel-header-title {
          .workflow-item-title {
            color: #b9bec6;
          }

          .info-accordion-item-title {
            color: #b9bec6;

            .step-index {
              border: 2px solid #b9bec6;
              background-color: #eeeff2;
              color: #b9bec6;
            }
          }
        }
      }
    }

    &[class*="-is_completed"] {
      .mat-expansion-panel-header {
        background-color: $color-primary-500;
        color: white;

        &:hover {
          background-color: $color-primary-600 !important;
        }

        >.mat-expansion-indicator:after {
          color: white;
        }

        .mat-expansion-panel-header-title {
          .info-accordion-item-title {
            .step-index {
              border: 2px solid white;
              background-color: white;
              color: $color-primary-500;
            }
          }
        }
      }
    }
  }

  .mat-mdc-expanded {
    background-color: #2c3036;
  }

  .mat-expansion-panel-content {
    background-color: $white !important;
  }

  .mat-expansion-panel-body {
    background-color: #eeeff2;
    padding: 20px;
  }

  .mat-mdc-content {
    overflow: unset;
  }

  .panel-header>.mat-expansion-indicator:after {
    color: $green;
  }
}

mat-accordion.settings {
  .mat-expansion-panel {
    border-radius: 0 !important;
    box-shadow: none;

    &.mat-expansion-panel-spacing {
      margin: 0;
    }

    &.mat-expanded {
      border-bottom: 1px solid $light-gray;

      .mat-expansion-panel-header {
        border-bottom-width: 0;
      }
    }

    .mat-expansion-panel-header {
      height: 36px;
      border-radius: 0;
      font-weight: bold;
      border-bottom: 1px solid $light-gray;
      padding: 0 10px 0 10px;
      line-height: 36px;
    }

    .mat-expansion-panel-body {
      padding: 10px;
      word-break: break-word;

      .document-name-wrapper {
        width: 100%;
        overflow: hidden;

        .document-name-label {
          display: inline-block;
          width: 100%;
        }

        .document-name-text {
          display: inline-block;
          word-break: break-all;
          width: 100%;
        }
      }
    }
  }
}

//  Date range picker + Simple date picker
.mat-calendar.app-datepicker,
.mat-calendar.app-datepicker-simple {
  padding: 20px;

  .mat-calendar-table-header th {
    @include inter-semibold;
    font-size: 0.625rem;
    color: $dark-gray-rgba;
  }

  .mat-calendar-table-header-divider::after {
    left: 0px;
    right: 0px;
  }

  .mat-calendar-content {
    padding: 0;
  }
}

//  Date range picker + Simple date picker
.mat-calendar.app-datepicker .mat-calendar-content,
.mat-calendar.app-datepicker-simple .mat-calendar-content {
  width: auto;
  height: auto;

  .mat-calendar-body-selected {
    background-color: $color-primary-500;
    color: $white;
  }

  .mat-calendar-body-in-range::before {
    background: $color-primary-100;
  }
}

//  Simple date picker
.mat-calendar.app-datepicker-simple {
  .mat-calendar-header {
    padding: 0;

    .mat-calendar-controls {
      margin-bottom: 5px;

      button:first-child {
        margin-left: -8px;

        .mat-calendar-button-0 {
          font-weight: $semi-bold-font;
        }

        .mat-calendar-arrow {
          margin-left: 12px;
        }
      }
    }
  }

  .mat-calendar-content {
    .mat-calendar-table {
      .mat-calendar-table-header {
        th {
          padding: 0;
          text-transform: uppercase;
        }
      }

      .mat-calendar-body {
        td {
          .mat-calendar-body-cell-content {
            font-size: 14px;
            line-height: 20px;
          }

          &.mat-calendar-body-disabled {
            .mat-calendar-body-cell-content {
              color: $label-light-rgba;
            }
          }
        }
      }
    }
  }
}

.mat-calendar {
  mat-month-view {
    table {
      tr[aria-hidden="true"] {
        display: none;
      }
    }
  }
}

.app-datepicker {
  .mat-calendar-body-label:first-of-type {
    visibility: hidden;
  }
}

app-datepicker {
  button {
    .mdc-button__label {
      font-size: 14px;
    }
  }
}

.datepicker-content.mat-mdc-menu-panel.btr-basic.btr-select-dropdown {
  .mat-mdc-menu-content {
    max-width: 100% !important;
    max-height: 100% !important;
  }
}

.mat-mdc-menu-panel.btr-basic.btr-select-dropdown {
  &.filters {
    width: 235px;

    &.fluid {
      width: 390px;

      .mat-mdc-text-field-wrapper {
        border-bottom: solid 1px $gray-3;
      }
    }

    .mat-mdc-menu-content {
      max-width: inherit;
      max-height: inherit;

      .select-items-container {
        max-height: 246px;
        overflow: auto;
      }
    }
  }

  .mat-mdc-menu-content {
    max-width: 195px;
    max-height: 310px;

    .mat-mdc-form-field .mat-mdc-text-field-wrapper {
      margin: 0;
      padding: 10.5px;
    }

    button.mat-mdc-menu-item.option-button {
      line-height: 1;
      color: $charcoal-grey;

      app-option {
        .title {
          font-size: 16px;
        }

        .info {
          font-size: 12px;
        }
      }

      &:hover {
        background-color: $hover-on-white;
      }

      &.selected {
        background-color: $color-primary-500;
        color: $white;

        &:hover {
          background-color: $color-primary-700;
        }
      }
    }
  }
}

.mat-mdc-text-field-wrapper {
  width: 100%;
}

.mat-mdc-menu-panel.btr-basic.notifications-menu-panel {
  position: absolute;
  left: 62px;
  bottom: 16px;
  max-height: calc(100vh - 16px);
}

.mat-mdc-menu-panel.btr-basic {
  &.btr-table-actions-menu {
    position: absolute;
    right: 90%;
    top: -10px;
    border-radius: 4px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
  }

  .mat-mdc-menu-content {
    button.mat-mdc-menu-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: auto;
      padding: 16px;
      border-bottom: 1px solid $label-gray;

      &.button-with-arrow {
        padding-right: 36px;
      }

      span {
        line-height: 20px;
        font-size: 14px;
        white-space: nowrap;
      }

      .wrap-text {
        span {
          white-space: normal;
        }
      }

      :first-child {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      span[class^="icon-n-"] {
        font-size: 18px;
        margin-right: 15px;

        &.green-icon {
          color: $color-primary-500;
        }

        &.gray-icon {
          color: $dark-petrol-grey;
        }

        &.delete-icon,
        &.red-icon {
          color: $error;
        }

        &.globe-icon,
        &.delete-icon {
          color: $dark-petrol-grey;
        }
      }

      &:disabled {
        background-color: $label-gray;
        color: $warm-grey;
      }
    }
  }
}

.mat-mdc-menu-panel.dropdown-button-menu.review-versions-dropdown {
  .mat-mdc-menu-content {
    button.mat-mdc-menu-item {
      height: 62px;

      &:not(:last-child) {
        border-bottom: 1px solid $review-gray;
      }
    }
  }
}

.mat-mdc-menu-panel.btr-select-dropdown {
  right: unset !important;
  margin-top: 5px;
  min-width: 180px;

  &.width-auto {
    width: auto !important;
  }
}

div.mat-mdc-select-panel.mdc-menu-surface--open {
  padding: 0;

  &.hide-select {
    display: none;
  }

  &.package-sort-panel {
    margin-left: 5px;
    width: 180px;
    border-radius: 2px;

    color: red;

    &.visibility-panel {
      &.right {
        margin-left: -15px;
      }
    }

    &.right {
      margin-left: -10px;
    }

    &.sm {
      width: 110px;
    }

    .mat-mdc-option {
      height: 48px;
      padding: 14px 16px;
      color: $gray-4;
      font-size: 14px;
      letter-spacing: normal;

      &:not(:last-child) {
        border-bottom: 1px solid $gray-3;
      }

      &.mdc-list-item--selected:not(.mat-mdc-option-multiple) {
        background-color: $color-primary-500;

        .mdc-list-item__primary-text {
          color: $white;
        }

        mat-pseudo-checkbox {
          display: none;
        }

        &:hover {
          background-color: $color-primary-700;
        }
      }

      &.mdc-list-item--selected:not(.mat-mdc-option-disabled) {
        color: $white;
      }

      &:hover:not(.mat-mdc-option-disabled),
      &:focus:not(.mat-mdc-option-disabled) {
        background-color: $hover-on-white;
      }
    }
  }
}

.mat-mdc-select-panel {
  .mat-mdc-option {
    mat-pseudo-checkbox {
      display: none;
    }

    .mdc-list-item__primary-text {
      width: 100%;
      letter-spacing: normal;
    }

    &.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
      background-color: $color-primary-500;

      .mdc-list-item__primary-text {
        color: $white;
      }

      mat-pseudo-checkbox {
        display: none;
      }

      &:hover {
        background-color: $color-primary-700;
      }
    }

    &.mdc-list-item--selected:not(.mat-mdc-option-disabled):not(.mdc-list-item--disabled) {
      color: $white;
    }
  }
}

.mat-mdc-tooltip {
  &.custom-tooltip {
    background-color: $tooltip-bg;
    color: white;
    font-family: Inter, sans-serif;
    white-space: pre-line !important;
    @include app-text-style(0.75rem, 1.67, -0.2px, 600);
  }

  &.btr-basic {
    border-radius: 4px;
    background-color: $tooltip-bg;
    padding: 10px;

    // GENERAL TOOLTIP STYLE
    &.small {
      max-width: 250px;
      @include line-clamp(20);
      padding-bottom: 5px;
    }

    &.large {
      max-width: 600px;
      white-space: break-spaces;

      &.pre-line {
        white-space: pre-line !important;
      }
    }

    &.column-name-tooltip {
      margin-top: 25px !important;
    }

    .mdc-tooltip__surface {
      font-family: Inter, sans-serif;
      font-size: 0.75rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      background-color: $tooltip-bg;
      color: $white;
      padding: 0;
      box-sizing: border-box;
      max-height: inherit !important;
      white-space: pre-line !important;
    }
  }

  .mdc-tooltip__surface {
    text-align: left;
    letter-spacing: normal !important;
  }
}

.mat-mdc-checkbox.btr-checkbox {
  .mdc-form-field {
    .mdc-checkbox {
      padding: 0;
      margin-right: 8px;

      .mat-mdc-checkbox-touch-target {
        position: static;
        height: 18px;
        width: 18px;
        transform: none;
      }

      .mdc-checkbox__native-control:checked~.mdc-checkbox__background,
      .mdc-checkbox__native-control:indeterminate~.mdc-checkbox__background,
      .mdc-checkbox__native-control[data-indeterminate="true"]~.mdc-checkbox__background {
        background-color: $white;

        &:after {
          content: "";
          width: 10px;
          height: 10px;
          position: absolute;
        }
      }

      .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
        &:after {
          border-color: $color-primary-500;
          background-color: $color-primary-500;
        }
      }

      .mdc-checkbox__native-control[disabled]:checked~.mdc-checkbox__background {
        border-color: $color-primary-200;

        &:after {
          background-color: $color-primary-200;
        }
      }

      &:hover:not(.mat-mdc-checkbox-disabled) {
        .mdc-checkbox__native-control {
          &:checked~.mdc-checkbox__background {
            border-color: $color-primary-700;

            &:after {
              background-color: $color-primary-700;
            }
          }

          &:not(:checked)~.mdc-checkbox__background {
            border-color: $color-primary-500;
          }
        }
      }
    }

    &>label {
      margin: 0;
      padding: 0;
    }
  }
}

// Drop down fix, after upgrading to Angular Material 15:
.mat-mdc-form-field-type-mat-select {
  padding: 0;

  .mat-mdc-text-field-wrapper {
    padding: 0 12px;

    .mat-mdc-form-field-flex {
      .mat-mdc-form-field-infix {
        padding-top: 4px;
        padding-bottom: 4px;
        min-height: 0;

        .mat-mdc-select {
          .mat-mdc-select-value {
            .mat-mdc-select-value-text {
              .mat-mdc-select-min-line {
                letter-spacing: normal;
                font-weight: 600;
              }
            }
          }

          &.select-small-label {
            .mat-mdc-select-value {
              .mat-mdc-select-value-text {
                .mat-mdc-select-min-line {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled),
  .mdc-text-field--filled:not(.mdc-text-field--disabled):hover {
    background-color: transparent;
    background-color: transparent;
  }

  .mdc-line-ripple,
  .mat-mdc-form-field-focus-overlay {
    display: none;
  }
}

.mat-mdc-form-field.no-subscript-wrapper {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.cdk-overlay-container {
  .mat-mdc-select-panel.package-sort-panel {
    margin-top: 5px;

    &.right {
      margin-left: -10px;
    }
  }
}

.btn-actions {
  .mat-mdc-icon-button.mat-mdc-button-base {
    width: 32px;
    height: 20px;
    padding: 0;
  }
}

.mat-mdc-slide-toggle {
  .mdc-form-field {
    button.mdc-switch {
      &:enabled {
        .mdc-switch__track {
          &::before {
            background: $warm-grey;
          }
        }
      }

      &.mdc-switch--unselected {
        &:enabled {
          &:focus:not(:active) {
            .mdc-switch__track {
              &::before {
                background: $warm-grey;
              }
            }

            .mdc-switch__handle {
              &::after {
                background: $white;
              }
            }
          }

          &:hover {
            &:not(:focus):not(:active) {
              .mdc-switch__track {
                &::before {
                  background: $warm-grey;
                }
              }

              .mdc-switch__handle {
                &::after {
                  background: $color-primary-700;
                }
              }
            }
          }
        }

        .mdc-switch__handle {
          &::after {
            background: $white;

            &:hover {
              background: $color-primary-700;
            }
          }

          &:hover {
            &::after {
              background: $color-primary-700;
            }
          }
        }
      }

      &.mdc-switch--selected {
        &:enabled {
          .mdc-switch__track {
            &::after {
              background: $color-primary-200;
            }
          }

          .mdc-switch__handle {
            &::after {
              background: $color-primary-500;
            }
          }
        }

        &:hover {
          &:not(:focus):not(:active) {
            .mdc-switch__track {
              &::after {
                background: $color-primary-200;
              }
            }

            .mdc-switch__handle {
              &::after {
                background: $color-primary-700;
              }
            }
          }
        }
      }

      .mdc-switch__ripple {
        width: 38px;
        height: 38px;
      }

      .mdc-switch__icons {
        display: none;
      }
    }
  }

  // .mat-mdc-slide-toggle-bar {
  //   background-color: $warm-grey;
  // }
  // .mat-mdc-slide-toggle-thumb {
  //   background-color: $white;

  //   &:hover {
  //     background-color: $color-primary-500;
  //   }
  // }

  // &.mat-disabled {
  //   .mat-mdc-slide-toggle-bar {
  //     background-color: $gray-3;
  //   }
  //   .mat-mdc-slide-toggle-thumb {
  //     background-color: $gainsboro-gray;

  //     &:hover {
  //       background-color: $gainsboro-gray;
  //     }
  //   }
  // }

  // &.mat-checked {
  //   .mat-mdc-slide-toggle-bar {
  //     background-color: $color-primary-200;
  //   }
  //   .mat-mdc-slide-toggle-thumb {
  //     background-color: $color-primary-500;

  //     &:hover {
  //       background-color: $color-primary-700;
  //     }
  //   }

  //   &.mat-disabled {
  //     opacity: 1;
  //     .mat-mdc-slide-toggle-bar {
  //       background-color: $label-gray;
  //     }
  //     .mat-mdc-slide-toggle-thumb {
  //       background-color: $ultra-light-green-2;

  //       &:hover {
  //         background-color: $ultra-light-green-2;
  //       }
  //     }
  //   }
  // }
}

.mat-form-field-appearance-outline {
  .mat-mdc-form-field-flex {
    &:hover {
      .mat-mdc-form-field-outline-thick {
        color: $color-primary-500;
      }
    }
  }

  &.mat-mdc-focused {
    .mat-mdc-form-field-flex {
      .mat-mdc-form-field-outline-thick {
        color: $gray-66;
      }
    }

    .mat-mdc-form-field-label mat-label {
      color: $gray-66 !important;
      line-height: 1.3;
    }
  }

  &.mat-mdc-focused.mat-mdc-form-field-invalid {
    .mat-mdc-form-field-flex {
      .mat-mdc-form-field-outline-thick {
        color: $error;
      }
    }

    .mat-mdc-form-field-label mat-label {
      color: $error !important;
    }
  }
}

mat-select {
  &.open {
    .mat-mdc-select-arrow {
      transform: rotate(180deg);
    }
  }
}

.mat-mdc-form-field-suffix {
  mat-datepicker-toggle {
    &:not(.has-clock) {
      vertical-align: super;
    }
  }

  mat-icon {
    .arrow {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid;
      margin: 0 4px;
      color: rgba(0, 0, 0, 0.54);
    }
  }
}

.mat-mdc-form-field.btr-form-field.disable-float {
  &.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float.mat-mdc-focused .mat-mdc-form-field-outline-gap {
    border-top-color: unset;
  }

  &.mat-mdc-focused .mat-mdc-form-field-label mat-label {
    display: none;
  }
}

// CDK Drag and Drop
.cdk-drag.sdox-sections-drag-preview {
  .step-title-container {
    font-weight: 600;
    font-size: 0.75rem;
    display: flex;

    .step-title {
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
      justify-items: center;
      display: flex;
    }

    .step-title-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 170px;
      color: $sdox-section-text;
    }

    .icon-container {
      &.toc {
        position: static;
      }
    }
  }

  &.item-content-box {
    font-family: Inter, "Source Sans Pro", sans-serif;
    padding: 0 16px;
    display: flex;
    align-items: center;
    color: $dark-petrol-grey;
    flex-direction: row;
    justify-content: space-between;
    background: white;
    font-size: 14px;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.11);

    .config-title-wrapper {
      width: calc(100% - 28px);
      white-space: nowrap;
      overflow: hidden;
      user-select: none;
      font-weight: 600;
      flex: 1;

      &.is-template {
        color: $green;
      }

      .config-title {
        width: 100%;
        font-size: 0.75rem;
        user-select: none;
        overflow: hidden;
        white-space: nowrap;
        justify-items: center;
        display: flex;
      }

      .config-title-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 165px;
        font-size: 12px;
      }
    }
  }
}

.cdk-drag.columns-drag-preview {
  z-index: 100001 !important;

  .column-container {
    background-color: $hover-on-white;
  }

  mat-form-field {
    margin-bottom: 0 !important;
  }

  .column-actions-buttons {
    text-align: right;
    color: $gray-66;
    font-size: 12px;
    font-weight: 600;

    .column-edit,
    .column-delete {
      cursor: pointer;

      span {
        margin: 5px 0 5px 5px;
        padding: 4px;
        display: inline-block;
        cursor: pointer;
        font-size: 12px;
      }
    }

    .action-disabled {
      color: $warm-grey !important;

      .column-delete .icon-n-delete {
        color: $disabled-red !important;
      }
    }

    .column-delete .icon-n-delete {
      color: $error;
      margin-left: 14px;
    }

    .column-edit .icon-n-edit {
      color: $color-primary-600;
    }

    .column-disabled {
      color: $gray;
      cursor: default;
    }
  }
}

.cdk-overlay-container {
  z-index: 1000;
}

div.mdc-dialog__title::before,
span.mdc-dialog__title::before {
  display: none;
  content: unset;
}

.mat-mdc-radio-button {
  cursor: pointer;

  .mdc-form-field {
    .mdc-radio {
      padding: 0;

      .mdc-radio__native-control:enabled {

        &:checked+.mdc-radio__background .mdc-radio__outer-circle,
        &+.mdc-radio__background .mdc-radio__inner-circle {
          border-color: $color-primary-500;
        }
      }

      &:hover {
        .mdc-radio__native-control:enabled:checked+.mdc-radio__background {

          .mdc-radio__outer-circle,
          .mdc-radio__inner-circle {
            border-color: $color-primary-500;
          }
        }
      }
    }

    &>label {
      padding-left: 8px;
      color: $dark-gray;
      cursor: pointer;
      letter-spacing: normal;
      font-weight: 600;
    }
  }

  &.mat-mdc-radio-checked {
    .mdc-form-field {
      &>label {
        color: $dark-gray-rgba;
      }
    }
  }
}

.cdk-overlay-pane {
  &.mat-mdc-select-panel-above {
    div.mat-mdc-select-panel {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  div.mat-mdc-autocomplete-panel {
    background-color: $bg-search;
    padding: 0;
  }
}

.mat-mdc-form-field {
  &.mat-mdc-paginator-page-size-select {
    .mat-mdc-text-field-wrapper {
      height: 40px;
    }
  }
}

textarea.mat-mdc-form-field-textarea-control {
  padding-left: 10px;
  padding-right: 10px;
  height: 100%;
}

// Fix for the mat-paginator popup
.mdc-menu-surface {
  &[aria-labelledby^="mat-paginator"] {
    .mat-mdc-option.mdc-list-item {
      padding: 0 0 0 6px;

      .mdc-list-item__primary-text {
        margin-right: 0;
        font-size: 12px;
      }

      &.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
        background-color: rgba(0, 0, 0, 0.04);

        .mdc-list-item__primary-text {
          color: $warm-grey;
        }
      }
    }
  }
}

.mat-mdc-paginator-container {
  .mat-mdc-form-field-type-mat-select {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          .mat-mdc-select {
            .mat-mdc-select-value {
              .mat-mdc-select-value-text {
                .mat-mdc-select-min-line {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}

mat-paginator {
  .mat-mdc-form-field:not(.mat-form-field-disabled):not(.mat-form-field-invalid) {
    &.mat-mdc-form-field-type-mat-select {
      .mat-mdc-text-field-wrapper {
        .mat-mdc-form-field-flex {
          .mat-mdc-form-field-infix {
            .mat-mdc-select {
              .mat-mdc-select-value {
                .mat-mdc-select-value-text {
                  .mat-mdc-select-min-line {
                    font-size: 12px;
                  }
                }
              }
            }
          }

          .mdc-notched-outline {

            .mdc-notched-outline__leading,
            .mdc-notched-outline__notch,
            .mdc-notched-outline__trailing {
              border: none;
            }
          }
        }
      }
    }
  }
}

.mat-mdc-table {
  .mat-sort-header-arrow {
    color: $white;
  }
}

.mat-mdc-menu-panel {
  .mat-mdc-menu-content {
    >.mat-mdc-form-field {
      .mat-mdc-text-field-wrapper {
        .mdc-notched-outline {
          display: none;
        }
      }

      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }

      .mat-mdc-form-field-flex {
        border: $light-gray-rgba;
        border-radius: 5px;
      }
    }
  }
}

// Fix for the select overlay: https://github.com/angular/components/issues/26000
.cdk-overlay-pane:has(.mat-mdc-select-panel) {
  min-width: fit-content;
}

.mat-mdc-error {
  color: $error;
}

.dropdown-button-menu.translate-y {
  transform: translateY(-51px);
}