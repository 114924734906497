@import "abstracts/variables";
@import "abstracts/mixins";

button.simple-button,
button.icon-button,
button.actions-button,
button.splitter-actions-button,
button.action-info-button {
  border-width: $button-border-width;
  border-style: solid;
  border-radius: $button-border-radius;

  &:disabled {
    border-color: rgba(0, 0, 0, 0.12) !important;
  }

  &.no-border {
    border: none !important;
  }

  &.primary {
    @include actions-button($white, $green, $green);
    &:not(:disabled) {
      &:hover {
        @include actions-button($white, $dark-green, $dark-green);
      }
    }
  }

  &.secondary {
    @include actions-button($green, $green, $white, $green);
    &:hover {
      @include actions-button($white, $dark-green, $dark-green);
    }
  }


  &.tertiary {
    @include actions-button($dark-gray-rgba, $label-gray, $label-gray, $dark-gray-rgba);
    &:hover {
      @include actions-button($dark-gray-rgba, $dark-gray-rgba, $label-gray, $dark-gray-rgba);
    }
  }

  &.outline {
    @include actions-button($green, $green, transparent, $green);
    &:hover {
      @include actions-button($dark-green, $dark-green, transparent, $dark-green);
    }
  }

  &.secondary-toggle {
    @include inter-semibold;
    background-color: $color-primary-50;
    color: rgba(0, 0, 0, 0.6);
    &:hover {
      background-color: $color-primary-200;
    }
    &.selected {
      color: $white;
      background-color: $color-primary-500;
    }
  }

  &.warn {
    @include actions-button($white, $orange, $orange);
  }

  &.danger {
    @include actions-button($error, $error, $white, $error);
    &:hover {
      @include actions-button($dark-red, $dark-red, $white, $dark-red);
    }
  }

  &.danger-bg {
    @include actions-button($white, $error, $error, $error);
  }

  &.xs {
    height: 1.4rem;
    @include app-text-style(0.75rem, normal, normal, 600);
  }

  &.small {
    height: 36px;
    @include app-text-style(0.875rem, normal, normal, 600);
  }

  &.medium {
    height: 40px;
    @include app-text-style(1rem, normal, normal, 600);
  }

  &.basic {
    @include actions-button(inherit, transparent, transparent, inherit);
    &:hover {
      @include actions-button(inherit, transparent, $pale-gray-two, inherit);
    }

    &.text-color-primary {
      @include actions-button($green, transparent, transparent, $green);
      &:hover {
        @include actions-button($dark-green, transparent, transparent, $dark-green);
      }
    }

    &.text-color-secondary {
      color: inherit;
    }

    &.text-color-warn {
      @include actions-button($orange, transparent, transparent, $orange);
      &:hover {
        @include actions-button($dark-orange, transparent, transparent, $dark-orange);
      }
    }

    &.text-color-danger {
      @include actions-button($error, transparent, transparent, $error);
      &:hover {
        @include actions-button($dark-red, transparent, transparent, $dark-red);
      }
    }

    &.text-color-cancel {
      @include actions-button($cancel-gray, transparent, transparent, inherit);
      &:hover {
        @include actions-button($green, transparent, transparent, inherit);
      }
    }

    .icon {
      color: inherit;
    }

    &.no-hover-bg {
      &:hover {
        &.text-color-primary {
          @include actions-button($dark-green, transparent, inherit);
        }
      }
    }
  }

  &.height-auto {
    height: auto;
  }
}

button.simple-button {
  &.primary {
    .text {
      .label-info {
        color: $color-primary-100;
      }
    }
  }
}

button.actions-button {
  &:disabled {
    .icon {
      color: inherit !important;
    }
  }

  &.small {
    .icon {
      font-size: 1.5rem;
      margin: 0 4px;
    }
  }

  &.medium {
    .icon {
      font-size: 1.8rem;
      line-height: 40px;
      margin: 0 4px;
    }
  }
}