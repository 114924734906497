
::ng-deep ejs-diagram {
  .foreign-object {
    .foreign-object {
      div {
        svg {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}