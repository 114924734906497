@import "abstracts/mixins";

.table-input {
  width: 60% !important;
}

.table-container {
  flex-wrap: nowrap;
  min-width: 100%;
}

//  New style
table.mat-mdc-table.btr-table {
  background: transparent;
  font-family: Inter, sans-serif;

  tr.mat-mdc-header-row {
    font-weight: 600;
    background-color: $charcoal-grey;
    height: auto; // 42px?

    &.big-table-header {
      height: 62px;
    }

    th.mat-mdc-header-cell {
      color: $white;
      &:first-child {
        border-radius: 4px 0 0 0;
      }

      &:last-child {
        border-radius: 0 4px 0 0;
      }

      .mat-mdc-sort-header-arrow {
        color: $white;
      }
    }
  }

  tr.mat-mdc-row {
    height: auto;
    background: $white;

    &:first-child {
      td.mat-mdc-cell,
      td.mat-cell {
        border-top: 2px solid $body-background-color;
      }
    }

    &:last-child {
      td.mat-mdc-cell,
      td.mat-cell {
        &:first-child {
          border-radius: 0 0 0 4px;
        }

        &:last-child {
          border-radius: 0 0 4px 0;
        }
      }
    }
  }

  th.mat-mdc-header-cell,
  td.mat-mdc-cell,
  td.mat-cell {
    font-size: 14px;
    padding: $table-cell-y-padding $table-cell-x-padding;
  }

  td.mat-mdc-cell,
  td.mat-cell {
    color: $charcoal-grey;
    border-bottom: 2px solid $body-background-color;
    font-family: Inter, sans-serif;
    letter-spacing: -0.2px;

    // set cell min-width?
    span.cell-text {
      @include line-clamp(2);
    }
    &.no-results {
      font-weight: $bold-font;
      font-size: 18px;
      padding: 50px;
      text-align: center;
      background: $hover-on-white;
      color: $charcoal-grey;
      height: 395px;  // NRESQ-5981 - The table must have a specific height when there is not data, as per the design
    }
    &.no-results.small {
      @include app-text-style(0.875rem, 1.43rem, -0.4px);
      padding: 11px;
      text-align: center;
      background: $white;
      color: $charcoal-grey;
    }
  }

  th.mat-mdc-header-cell.td-image,
  td.mat-mdc-cell.td-image,
  td.mat-cell.td-image {
    width: 48px;

    .avatar-text {
      height: 16px;
      font-size: 9px;
      line-height: 13px;
      color: $white;
      border-radius: 2px;
    }
  }

  th.mat-mdc-header-cell.td-actions,
  td.mat-mdc-cell.td-actions,
  td.mat-cell.td-actions {
    width: 32px;

    button {
      @include flex-center();

      .mat-icon {
        font-size: 20px;

        &:hover {
          color: $color-primary-500;
        }
      }

      .mat-mdc-button-persistent-ripple,
      .mat-mdc-button-ripple {
        display: none;
      }
    }
  }

  &.btr-table--select {
    tr.mat-mdc-row {
      &:hover {
        background-color: $ultra-light-green;
        cursor: pointer;
      }

      &.selected {
        background-color: $hover-on-white;

        &:hover {
          cursor: default;
        }
      }

      td.mat-mdc-cell,
      td.mat-cell {
        &:first-child {
          font-weight: $semi-bold-font;
        }
      }
    }
  }
  &.scrolling-table {
    &.loading {
      pointer-events: none;
    }
  }
}

.btr-table-fe {
  background: transparent;
  font-size: 14px;

  mat-header-row {
    min-height: auto; // 42px?
    border: none;
    background-color: $charcoal-grey;

    mat-header-cell.mat-mdc-header-cell {
      color: $white;
      background-color: $charcoal-grey;
      font-size: 14px;
      font-weight: 600;

      .table-editable-cell {
        width: 100%;
        height: 100%;
      }
    }
  }

  mat-row {
    border: none;

    mat-cell {
      background-color: $white;
      color: $charcoal-grey;
      border-bottom: 2px solid $body-background-color;
      align-items: flex-start;
      justify-content: flex-start;
      text-align: left;

      .row-actions-container-left,
      .row-actions-container-right {
        display: none;
        height: 100%;
        max-height: 50px;
        flex-direction: column;
        position: absolute;
        transform: translateY(-1px);

        .reorder-icon {
          color: $color-primary-500;
        }
      }
      .row-actions-container-left {
        justify-content: center;
        left: -17px;
        padding: 0px 14px 14px;
      }

      .row-actions-container-right {
        justify-content: space-between;
        right: -5px;
        padding: 0px 5px 14px 14px;
      }
    }

    &:not(.view-mode):hover {
      .row-actions-container-left,
      .row-actions-container-right {
        display: flex;
      }

      mat-cell:not(.disabled-cell) {
        color: $white;
        background-color: $color-primary-500;

        app-table-impact-assessment-cell {
          .impact-container {
            span {
              color: white;
            }
          }
        }
        app-links {
          span {
            color: white;
          }
        }
        .table-screenshot-cell app-styleguide-simple-button {
          span, button {
            color: white;
          }
          button.green-link .icon {
            color: white; // override
          }
        }
      }
    }
  }

  mat-header-row mat-header-cell,
  mat-row mat-cell {
    white-space: pre-line;
    padding: $table-cell-y-padding $table-cell-x-padding;
    line-height: 1.43;
    word-break: break-word;

    app-header-lock {
      display: inline-block;
      margin-left: 5px;
      vertical-align: middle;
    }

    &.mat-mdc-column-impactAssessment {
      max-width: 145px !important;
      min-width: 145px !important;
    }
    &.id-column,
    &.links-column:not(.remove-max-width) {
      max-width: 115px !important;
      min-width: 115px !important;

      &.tm-link-column {
        max-width: 500px !important;
        min-width: 500px !important;
      }
    }
    &.mat-mdc-column-test_result_link:not(.remove-max-width) {
      max-width: 145px !important;
      min-width: 145px !important;
    }
    &.has-na-checkbox {
      max-width: 215px !important;
      min-width: 215px !important;
    }

    &:not(:first-child):not(:last-child) {
      min-width: 78px;
    }
  }
}

th.mat-mdc-header-cell {
  color: #0000008a;
}

th.mat-mdc-header-cell, td.mat-mdc-cell, td.mat-mdc-footer-cell {
  padding: 0;
}

th.mat-mdc-header-cell:first-of-type, td.mat-mdc-cell:first-of-type, td.mat-mdc-footer-cell:first-of-type {
  padding-left: 24px;
}

th.mat-mdc-header-cell:last-of-type, td.mat-mdc-cell:last-of-type, td.mat-mdc-footer-cell:last-of-type {
  padding-right: 24px;
}