.mat-mdc-checkbox {
  .mat-mdc-checkbox-frame {
    border-color: rgba(0, 0, 0, 0.38);
  }
  &.mat-mdc-checkbox-checked {
    .mat-mdc-checkbox-background {
      background-color: rgba(0, 0, 0, 0.38);
    }
    .mat-mdc-checkbox-frame {
      border-color: transparent;
    }

    &.checkbox-color-222222 {
      .mat-mdc-checkbox-background {
        background-color: #222222;
      }
    }

    &.checkbox-color-fac710 {
      .mat-mdc-checkbox-background {
        background-color: #fac710;
      }
    }

    &.checkbox-color-f24726 {
      .mat-mdc-checkbox-background {
        background-color: #f24726;
      }
    }

    &.checkbox-color-da0163 {
      .mat-mdc-checkbox-background {
        background-color: #da0163;
      }
    }

    &.checkbox-color-9510ac {
      .mat-mdc-checkbox-background {
        background-color: #9510ac;
      }
    }

    &.checkbox-color-652cb4 {
      .mat-mdc-checkbox-background {
        background-color: #652cb4;
      }
    }

    &.checkbox-color-414bb2 {
      .mat-mdc-checkbox-background {
        background-color: #414bb2;
      }
    }

    &.checkbox-color-2e9bf0 {
      .mat-mdc-checkbox-background {
        background-color: #2e9bf0;
      }
    }

    &.checkbox-color-13cdd4 {
      .mat-mdc-checkbox-background {
        background-color: #13cdd4;
      }
    }

    &.checkbox-color-cee741 {
      .mat-mdc-checkbox-background {
        background-color: #cee741;
      }
    }
  }

  &.checkbox-color-222222 {
    .mat-mdc-checkbox-frame {
      border-color: #222222;
    }
  }

  &.checkbox-color-fac710 {
    .mat-mdc-checkbox-frame {
      border-color: #fac710;
    }
  }

  &.checkbox-color-f24726 {
    .mat-mdc-checkbox-frame {
      border-color: #f24726;
    }
  }

  &.checkbox-color-da0163 {
    .mat-mdc-checkbox-frame {
      border-color: #da0163;
    }
  }

  &.checkbox-color-9510ac {
    .mat-mdc-checkbox-frame {
      border-color: #9510ac;
    }
  }

  &.checkbox-color-652cb4 {
    .mat-mdc-checkbox-frame {
      border-color: #652cb4;
    }
  }

  &.checkbox-color-414bb2 {
    .mat-mdc-checkbox-frame {
      border-color: #414bb2;
    }
  }

  &.checkbox-color-2e9bf0 {
    .mat-mdc-checkbox-frame {
      border-color: #2e9bf0;
    }
  }

  &.checkbox-color-13cdd4 {
    .mat-mdc-checkbox-frame {
      border-color: #13cdd4;
    }
  }

  &.checkbox-color-cee741 {
    .mat-mdc-checkbox-frame {
      border-color: #cee741;
    }
  }
}


